[data-braintree-id="methods-label"] {
    display: none;
  }



  [data-braintree-id="card"] {
    color: #0077B6;
    border: 2px solid #0077B6;
    border-color: none;
  }


  .braintree-method--active{
    border: 2px solid #0077B6;
    border-color: #0077B6;
  }

  .braintree-methods--active:not(.braintree-methods--edit) .braintree-method--active .braintree-method__check {
    background-color: #0077b6;
  }