@font-face {
    font-family: 'capitolium';
    font-style: normal;
    font-weight: 400;
    src: url(./Capitolium-Regular.ttf);
}

@font-face {
    font-family: 'capitolium-italic';
    font-style: normal;
    font-weight: 400;
    src: url(./Capitolium-Italic.ttf);
}

@font-face {
    font-family: 'capitolium-bold';
    font-style: normal;
    font-weight: 600;
    src: url(./Capitolium-Bold.ttf);
}